
import { Options, Vue } from 'vue-class-component'
import theRankings from '@/components/Frontend/Ranking/index.vue'

@Options({
  components: {
    theRankings
  }
})

export default class Rankinglist extends Vue {}
